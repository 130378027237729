
import { defineComponent, PropType } from "vue";
import { AnswerType } from "@/interfaces/AnswerType";

export default defineComponent({
	name: "BinaryChoice",
	// components: {BinaryChoiceButton},
	emits: ["answerClicked", "setAnswer"],
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
	inject: ["assetPath"],
	mounted() {
		this.$emit("answerClicked", null);
	},
});
