
import { defineComponent } from "vue";

export default defineComponent({
	name: "Navigation",
	props: {
		prevVisible: {
			type: Boolean,
		},
		currentAnswerValue: {
			type: Number,
			default: null
		}
	},
	emits: ["prevQuestion", "nextQuestion"],
});
