
import { defineComponent, PropType } from "vue";
import { AnswerType } from "@/interfaces/AnswerType";
import anime from "animejs";

export default defineComponent({
	name: "SteplessSlider",
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
	emits: ["answerClicked"],
	inject: ["assetPath"],
	data() {
		return {
			sliderValue: 50,
			sliderRange: 100,
			giveInfo: null as any,
			showInfoText: false,
		};
	},
	watch: {
		answers: {
			deep: true,
			handler() {
				this.$emit("answerClicked", this.answers![this.currentAnswer].id);
				this.sliderValue = 50;

				if (this.giveInfo) {
					clearInterval(this.giveInfo);
					this.giveInfo = null;
				}

				// this.giveInfo = setInterval(() => {
				// 	this.showInfoText = true;
				// }, 10000);
			},
		},
		sliderValue() {
			if (this.giveInfo) {
				clearInterval(this.giveInfo);
				this.giveInfo = null;
			}

			// this.giveInfo = setInterval(() => {
			// 	this.showInfoText = true;
			// }, 10000);
		},
	},
	computed: {
		currentAnswer() {
			const answerCount = this.answers?.length ?? 1;
			const answerRange = this.sliderRange / answerCount;

			if (this.sliderValue == this.sliderRange) return this.answers!.length - 1;

			return Math.floor(this.sliderValue / answerRange);
		},
		gradiantSliderValue() {
			if (this.sliderValue > 15 && this.sliderValue < 85) return this.sliderValue;

			return this.sliderValue > 15 ? Number(this.sliderValue) - 5 : Number(this.sliderValue) + 5;
		},
	},
	mounted() {
		this.giveInfo = setInterval(() => {
			this.giveInfoAboutUsage(this.sliderValue);
			// this.showInfoText = true;
		}, 10000);

		this.$emit("answerClicked", this.answers ? this.answers[this.currentAnswer].id : null);
	},

	beforeUnmount() {
		clearInterval(this.giveInfo);
	},

	methods: {
		giveInfoAboutUsage(innitVal: number) {
			console.log(innitVal);

			const obj = { n: innitVal };
			console.log(innitVal);
			anime({
				targets: obj,
				n: [innitVal + 5, innitVal - 5, innitVal + 5, innitVal - 5, innitVal],
				duration: 1500,
				easing: "linear",
				update: () => {
					this.sliderValue = obj.n;
				},
			});
		},
		infoAcknowledged() {
			clearInterval(this.giveInfo);
			this.showInfoText = false;
			this.giveInfo = null;
		},
	},
});
