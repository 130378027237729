import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-431b5263")
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "progressbar" }
const _hoisted_3 = { class: "questionCount" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "progress",
        style: _normalizeStyle({ width: Math.floor(((_ctx.progress - 1) / _ctx.overall) * 100) + '%' })
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString((_ctx.progress > _ctx.overall) ? _ctx.overall : _ctx.progress) + " / " + _toDisplayString(_ctx.overall), 1)
  ]))
}