export enum QuestionComponents {
  MultipleChoice='MultipleChoice',
  MultiplePictureChoice='MultiplePictureChoice',
  BinaryChoice='BinaryChoice',
  SwipeChoice='SwipeChoice',
  ProfileChoice='ProfileChoice',
  ResultChoice='ResultChoice',
  StartChoice='StartChoice',
  SteplessSliderChoice='SteplessSliderChoice',
  MultipleChipSelection='MultipleChipSelection',
  IntermediateScreenWaves='IntermediateScreenWaves',
  VersusChoice='VersusChoice'
}
