import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-65e782c8")
const _hoisted_1 = { class: "SwipeChoice" }
const _hoisted_2 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
      return (_openBlock(), _createElementBlock("button", {
        class: "button",
        key: answer.id,
        onClick: ($event: any) => (this.$emit('answerClicked', answer.id))
      }, _toDisplayString(answer.text), 9, _hoisted_2))
    }), 128))
  ]))
}