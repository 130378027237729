
import { defineComponent } from "vue";
import Quiz from "@/components/Quiz.vue";

export default defineComponent({
	name: "App",
	data() {
		return {
			assetPath: "",
			apiEndpoint: "",
			shopUrl: "",
		};
	},
	mounted() {
		this.assetPath = this.$el.parentNode.dataset.assetPath;
		this.apiEndpoint = this.$el.parentNode.dataset.apiEndpoint;
		this.shopUrl = this.$el.parentNode.dataset.shopUrl;
	},
	components: {
		Quiz,
	},
});
