
import { defineComponent, PropType } from "vue";
import { AnswerType } from "@/interfaces/AnswerType";

export default defineComponent({
	name: "MultipleChipSelection",
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
	emits: ["answerClicked"],
	inject: ["assetPath"],
	data() {
		return {
			selectedChipAnswerIds: [] as number[],
		};
	},
	watch: {
		selectedChipAnswerIds: {
			deep: true,
			handler() {
				this.$emit("answerClicked", this.selectedChipAnswerIds[0]);
			},
		},
	},
	methods: {
		onChipClicked(e: any) {
			var index = this.selectedChipAnswerIds.indexOf(Number(e.target.id));

			var selectionCopy = this.selectedChipAnswerIds;

			index !== -1 ? selectionCopy.splice(index, 1) : selectionCopy.push(Number(e.target.id));

			this.selectedChipAnswerIds = selectionCopy;
		},
	},
});
