
import { defineComponent, PropType } from "vue";
import { AnswerType } from "@/interfaces/AnswerType";
import anime from "animejs";

export default defineComponent({
	name: "AnimatedWaves",
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
	emits: ["answerClicked", "setAnswer"],
	mounted() {
		this.$emit("answerClicked", this.answers![0].id);
		anime({
			targets: this.$refs.waveOne,
			duration: 2000,
			direction: "alternate",
			translateX: "-50%",
			loop: true,
			opacity: 0.7,
			easing: "linear",
		});
		anime({
			targets: this.$refs.waveTwo,
			duration: 1500,
			direction: "alternate",
			translateX: "0",
			loop: true,
			opacity: 0.9,
			easing: "linear",
		});
		anime({
			targets: this.$refs.waveThree,
			duration: 1800,
			direction: "alternate",
			translateX: "-50%",
			loop: true,
			easing: "linear",
		});

		anime({
			targets: this.$refs.container,
			translateY:  "-130vh",
			duration: 8000,
			// easing: "linear",
		});
	},
	methods: {
		toNextQuestion() {
			this.$emit("setAnswer", null);
		},
	},
});
