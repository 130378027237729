import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3fd3ddca")
const _hoisted_1 = {
  key: 0,
  class: "navigation"
}
const _hoisted_2 = ["disabled"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.prevVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('prevQuestion')), ["prevent"]))
        }, "ZURÜCK"),
        _createElementVNode("button", {
          class: _normalizeClass(["button", !_ctx.currentAnswerValue ? 'disabled' : '']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('nextQuestion'))),
          disabled: !_ctx.currentAnswerValue
        }, "WEITER", 10, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}