import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../public/img/tailorwine_vs.svg'


_pushScopeId("data-v-05462cf7")
const _hoisted_1 = { class: "VersusChoice" }
const _hoisted_2 = { class: "versusChoiceImage" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiplePictureChoiceButton = _resolveComponent("MultiplePictureChoiceButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: answer.id
      }, [
        _createVNode(_component_MultiplePictureChoiceButton, {
          class: "buttonComponent",
          style: {"width":"20vw"},
          versusMode: "",
          answer: answer,
          "show-caption": true,
          onClick: ($event: any) => (_ctx.answerClicked(answer.id)),
          selectedAnswer: _ctx.selectedAnswerId === answer.id
        }, null, 8, ["answer", "onClick", "selectedAnswer"]),
        _createElementVNode("div", _hoisted_2, [
          (answer.id !== _ctx.answers[_ctx.answers.length - 1].id)
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : _createCommentVNode("", true)
        ])
      ], 64))
    }), 128))
  ]))
}