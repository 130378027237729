import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9340d8be")
const _hoisted_1 = { class: "text" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "explanation" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "explanation" }
const _hoisted_6 = {
  key: 0,
  class: "picture"
}
const _hoisted_7 = ["src", "alt"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.question.text), 1),
    (_ctx.displayFrame(_ctx.question.questionType) && _ctx.question.picture && _ctx.assetPath)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "frame",
          style: _normalizeStyle({backgroundImage: 'url(' + _ctx.assetPath + 'frame.png)' })
        }, [
          _createElementVNode("img", {
            src: _ctx.assetPath + _ctx.question.picture,
            alt: _ctx.question.text
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.question.explanation), 1)
        ], 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.question.explanation), 1),
          (_ctx.question.picture && _ctx.assetPath)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: _ctx.assetPath + _ctx.question.picture,
                  alt: _ctx.question.text
                }, null, 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true)
        ])),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.questionType), {
      onAnswerClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('answerClicked', $event))),
      onSetAnswer: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('setAnswer', $event))),
      answers: _ctx.question.answers,
      matches: _ctx.question.matches
    }, null, 8, ["answers", "matches"]))
  ], 64))
}