import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ecb35a08")
const _hoisted_1 = { class: "MultiplePictureChoice" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiplePictureChoiceButton = _resolveComponent("MultiplePictureChoiceButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
      return (_openBlock(), _createBlock(_component_MultiplePictureChoiceButton, {
        key: answer.id,
        answer: answer,
        showCaption: _ctx.answers.length > 3,
        onClick: ($event: any) => (_ctx.answerClicked(answer.id)),
        selectedAnswer: _ctx.selectedAnswerId === answer.id
      }, null, 8, ["answer", "showCaption", "onClick", "selectedAnswer"]))
    }), 128))
  ]))
}