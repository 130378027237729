
import { defineComponent, PropType } from "vue";
import { MatchType } from "@/interfaces/MatchType";
import { AnswerType } from "@/interfaces/AnswerType";

export default defineComponent({
	name: "ProfileChoice",
	emits: ["answerClicked", "setAnswer"],
	props: {
		matches: {
			type: Object as PropType<MatchType[]>,
		},
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
});
