import { openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"
import _imports_0 from '../../public/img/2209_tailorwine_tablet_quiz_ergebnis01.jpg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "ProfileChoice",
    src: _imports_0,
    style: {"height":"auto","width":"100vw"},
    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('setAnswer', _ctx.answers[0].id)))
  }))
}