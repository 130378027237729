
import { defineComponent, PropType } from "vue";
// import MultipleChoiceButton from "@/components/MultipleChoiceButton.vue";
import { AnswerType } from "@/interfaces/AnswerType";

export default defineComponent({
	name: "MultipleChoice",
	emits: ["answerClicked"],
	// components: { MultipleChoiceButton },
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
});
