
import { defineComponent, PropType } from "vue";
import MultiplePictureChoiceButton from "@/components/MultiplePictureChoiceButton.vue";
import { AnswerType } from "@/interfaces/AnswerType";

export default defineComponent({
	name: "MultiplePictureChoice",
	emits: ["answerClicked"],
	components: { MultiplePictureChoiceButton },
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
	data() {
		return { selectedAnswerId: -1 };
	},
	methods: {
		answerClicked(answerId: number) {
			this.selectedAnswerId = answerId;
			this.$emit("answerClicked", answerId);
		},
	},
	mounted(){
		this.$emit("answerClicked", null)
	}
});
