import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-02ad6170")
const _hoisted_1 = {
  class: "container",
  ref: "container"
}
const _hoisted_2 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "waveBox",
  viewBox: "0 0 7070.46 878.33",
  ref: "waveContainer"
}
const _hoisted_3 = {
  id: "Ebene_25",
  "data-name": "Ebene 25"
}
const _hoisted_4 = {
  ref: "waveOne",
  class: "cls-1 waveOne",
  style: {"transform":"scale(2)"},
  d: "M-5.51,297.76c176,0,343.92-257.15,781-257.15C1290.55,40.61,1450.53,306,1953.92,306c397.9,0,490.33-230.86,893.92-230.86,382.09,0,534.27,261.47,967.42,261.47,108,0,230.22-15,372.76-63.59,237.29-80.88,465-212.14,762.8-245.81C5248.17-1.19,5575.85-39.2,6203,243.79c498.1,198.77,877.56,120.89,877.56,120.89l1.09,291.5-7089,6.7S-5.69,291.1-5.51,297.76Z"
}
const _hoisted_5 = {
  id: "Ebene_23",
  "data-name": "Ebene 23"
}
const _hoisted_6 = {
  ref: "waveTwo",
  class: "cls-2 waveTwo",
  style: {"transform":"scale(2) translateX(-50%)"},
  d: "M-.49,404c97-4.4,402.34-272,827.46-270.31,364.47,1.42,716,276.5,1181.28,276.5,283.12,0,493-232.56,820.07-232.56,307.52,0,600.28,255.42,971.88,255.42,248.29,0,448.89-67.41,564-113.45,140.57-56.23,413.51-171.08,711.26-204.74C5372.8,86.51,5575.85,72.15,6203,355.14,6701.11,553.9,7080.57,476,7080.57,476l1.09,291.5-7084,1.61S-5,395.71-.49,404Z"
}
const _hoisted_7 = {
  id: "Ebene_24",
  "data-name": "Ebene 24"
}
const _hoisted_8 = {
  ref: "waveThree",
  class: "cls-3 waveThree",
  style: {"transform":"scale(2)"},
  d: "M2839.71,266.93c351.76-6.61,585.9,261.45,967.74,261.45,326.51,0,437.05-42.92,558-85.85,236.43-83.89,478.94-186.36,647.77-210.71,296.56-28.37,563.79-51.55,1189.31,231.44,496.8,198.77,875.27,120.89,875.27,120.89l1.09,291.5L-7.38,877.26s4.44-354.78,1.87-365.12c88.09-9.4,420.08-294.62,843-292.1,254.77,1.51,697.37,304.63,1145.5,292C2426.13,512.08,2488,273.55,2839.71,266.93Z"
}
const _hoisted_9 = {
  class: "background",
  ref: "bottom"
}
const _hoisted_10 = { class: "infoText" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("g", _hoisted_3, [
        _createElementVNode("path", _hoisted_4, null, 512)
      ]),
      _createElementVNode("g", _hoisted_5, [
        _createElementVNode("path", _hoisted_6, null, 512)
      ]),
      _createElementVNode("g", _hoisted_7, [
        _createElementVNode("path", _hoisted_8, null, 512)
      ])
    ], 512)),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.answers[0].text), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toNextQuestion && _ctx.toNextQuestion(...args)))
      }, "Weiter")
    ], 512)
  ], 512))
}