
import { defineComponent, PropType } from "vue";
import { AnswerType } from "@/interfaces/AnswerType";

export default defineComponent({
	name: "StartChoice",
	emits: ["setAnswer"],
	props: {
		answers: {
			type: Object as PropType<AnswerType[]>,
		},
	},
});
