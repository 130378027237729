import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3aa3203c")
const _hoisted_1 = { class: "MultipleChoice" }
const _hoisted_2 = { class: "answerForm" }
const _hoisted_3 = ["for", "onClick"]
const _hoisted_4 = ["id"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
        return (_openBlock(), _createElementBlock("label", {
          class: "answer",
          key: answer.id,
          for: answer.id.toString(),
          onClick: ($event: any) => (this.$emit('answerClicked', answer.id))
        }, [
          _createElementVNode("input", {
            type: "radio",
            id: answer.id.toString(),
            name: "answer"
          }, null, 8, _hoisted_4),
          _createTextVNode(" " + _toDisplayString(answer.text), 1)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}